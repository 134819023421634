<template>
  <div class="home">
    <Menu />
    <div class="gezi" ></div>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <Footer />
  </div>
</template>

<script>
import Menu from '@/components/Menu.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'HomeView',
  components: {
    Footer,
    Menu
  }
}
</script>
<style lang="less" scoped>
.home{
  width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.gezi {
  width: 100%;
  height: 2.57rem;
  @media screen and (max-width: 768px) {
    /* 在这里应用适合小屏幕设备的样式 */
    height: 4rem;
  }
}
</style>
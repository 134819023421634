<template>
  <div id="app">
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->

    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
  mounted () {
    this.setRem()
    // this.$message({
    //     message: 'Error',
    //     type: 'error',
    //     duration: 5 * 1000
    //   })
  },
  methods: {

    // rem适配
    setRem: function () {
      //这里是页面一加载就先执行一次,设置当前设备下,html的字体大小
      setHtmlFontSize();
      //添加resize和scroll事件，在尺寸变化时执行适配函数
      window.addEventListener("resize", setHtmlFontSize);
      window.addEventListener("scroll", setHtmlFontSize);
      function setHtmlFontSize () {
        // 1. 获取当前的屏幕的宽度和高度
        var windowWidth = document.documentElement.offsetWidth;
        var windowHeight = window.innerHeight;
        // 2.计算适配比例，取宽度和高度的最小比例
        // var scale = Math.min(windowWidth / 1920, windowHeight / 1080);
        var scale = Math.min(windowWidth / 1440, windowHeight / 1024);
        // 3.根据比例计算字体大小或其他元素的样式
        var htmlFontSize = scale * 30; // 假设设计图使用300px作为参照

        //给最大值 超过了600px默认为600px
        if (htmlFontSize > 200) {
          htmlFontSize = 200;
        }
        if (htmlFontSize < 11) {
          htmlFontSize = 11;
        }
        document.querySelector("html").style.fontSize = htmlFontSize + "px";
        // document.querySelector("html").style.minWidth = 1280 + "px";
        document.querySelector("html").style.minWidth = 400 + "px";
        document.querySelector("html").style.maxWidth = 1100 + "px";
        document.querySelector("html").style.margin = '0 auto';
      }
    },
  }
}
</script>
<style>
#app {
  font-size: 1.67rem;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store/index'
Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  // // 重定向
  //  redirect: { name: 'home' },
  //   // 重定向的目标路径可以是一个路径参数
  //   // redirect: to => `/${to.params.id}`
  //   component: HomeView,
  // },
  {
    path: '/',
    name: 'home',
    // 重定向
    redirect: { name: 'homeIndex' },
    // 重定向的目标路径可以是一个路径参数
    // redirect: to => `/${to.params.id}`
    // 嵌套路由
    // 注意：子路由的 path 前面不需要加 /
    // 相对于父路由的 path
    // 父路由的 path: /home
    // 子路由的 path: /index
    // 这样在 /home/index 路径下就能找到 HomeView/index.vue 组件
    // 注意：子路由的 path 前面不需要加 /
    // 相对于父路由的
    component: HomeView,
    //子路由
    children: [
      {
        path: 'index',
        name: 'homeIndex',
        // 注意：子路由的 path 前面不需要加 /
        // 相对于父路由的
        component: () => import('@/views/HomeView/index.vue') //首页
      }, {
        path: 'homeViewAbout',
        name: 'HomeViewAbout',
        // 注意：子路由的 path 前面不需要加 /
        // 相对于父路由的
        component: () => import('@/views/HomeView/about.vue') //公司简介
      }, {
        path: 'homeViewProduct',
        name: 'HomeViewProduct',
        // 注意：子路由的 path 前面不需要加 /
        // 相对于父路由的
        component: () => import('@/views/HomeView/product.vue') //产品介绍
      }
    ]
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  store.commit('router/SET_ROUTE_PATH', to.fullPath);
  next();
})
export default router

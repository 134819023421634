const state = {
  routePath:''
}
const mutations= {
  // 设置routePath的值
  SET_ROUTE_PATH: (state, path) => {
    state.routePath = path
  }
}
const actions= {
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
import 'normalize.css'
import '@/assets/style/common.less'
import "./utils/auto-import-elementUI"
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

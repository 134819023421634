<template>
  <div class="menu">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <div class="menuOptions" @click="menuOptionsFn">
      <div class="menuItem">
        <!-- @click="toHome" -->
        <div
          :class="$store.state.router.routePath == '/index' ? 'active' : ''"
          @click="toHome"
        >
          首页
        </div>
      </div>
      <div class="menuItem menuBorder">
        <div
          :class="
            $store.state.router.routePath == '/homeViewAbout' ? 'active' : ''
          "
          @click="homeAbout"
        >
          公司简介
        </div>
      </div>
      <div class="menuItem">
        <div
          :class="
            $store.state.router.routePath == '/homeViewProduct' ? 'active' : ''
          "
          @click="homeViewProduct"
        >
          产品介绍
        </div>
      </div>
    </div>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>|
    <span @click="homeAbout">homeAbout</span> -->
  </div>
</template>
<script>
export default {
  name: 'Menu',
  data () {
    return {
      routePath: ''
    }
  },
  mounted () {
    // this.routePath = this.$route.path;
    // // 设置vuex里的SET_ROUTE_PATH值
    // this.$store.commit('router/SET_ROUTE_PATH', this.$route.fullPath);
    // console.log(this.$store.state.router);

  },
  methods: {
    menuOptionsFn () {
      this.routePath = this.$route.fullPath
    },
    toHome () {
      this.$router.push({
        path: '/',
      }); // Navigate to "/about"
    },
    homeAbout () {
      // this.$router.push('/about'); // Navigate to "/about"
      this.$router.push({
        path: '/homeViewAbout',
      }); // Navigate to "/about"
    },
    homeViewProduct () {
      this.$router.push({
        path: '/homeViewProduct',
      }); // Navigate to "/about"
    }
  }

}
</script>
<style lang="less" scoped>
.menu {
  max-width: 1100px;
  width: 100%;
  height: 2.57rem;
  // background: #fff;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  @media screen and (max-width: 768px) {
    /* 在这里应用适合小屏幕设备的样式 */
    height: 4rem;
  }
  .logo {
    width: 8.73rem;
    height: 1.4rem;
    margin-top: -1.2rem;
    margin-left: 1.13rem;
  }
  .menuOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-right: 1.67rem;
    color: rgb(35, 35, 35);
    .menuItem {
      // 鼠标悬浮变为小手
      cursor: pointer;
      width: 6.5rem;
      height: 1.33rem;
      line-height: 1.33rem;
      text-align: center;
      font-size: 0.8rem;
      @media screen and (max-width: 768px) {
          /* 在这里应用适合小屏幕设备的样式 */
          font-size:1rem;
        }
      // padding: 0 .67rem;
      .active {
        width: 4.37rem;
        height: 1.33rem;
        line-height: 1.33rem;
        border-radius: 1.33rem;
        background: rgb(0, 94, 255);
        margin: 0 auto;
        color: #fff;
        @media screen and (max-width: 768px) {
          /* 在这里应用适合小屏幕设备的样式 */
          width: 6rem;
          height: 2rem;
          line-height: 2rem;
        }
      }
      @media screen and (max-width: 768px) {
        /* 在这里应用适合小屏幕设备的样式 */
        width: 8rem;
        height: 2.3rem;
        line-height: 2.3rem;
      }
    }
    .menuBorder {
      border-left: 2px solid #ccc;
      border-right: 2px solid #ccc;
    }
  }
}
</style>